<template>
  <div class="page-root pinggufuwu-root">
    <div class="pinggufuwu-banner-wrapper">
      <div class="pinggufuwu-banner">
        <div class="text">
          <div class="en">PROVIDE SYSTEM SUPPORT AND</div>
          <div class="zh">提供系统支持和运营服务支持</div>
          <div class="en">OPERATIONAL SERVICE SUPPORT</div>
          <div class="small">
            全方位解决金融机构二手车融资业务的难点，助力金融机构二手车业务的推广和开展
          </div>
          <div class="line"></div>
          <div class="logo">CHE YIN TONG</div>
        </div>
        <div class="top-right-area hidden-lg-and-up"></div>
        <img src="@/assets/pinggufuwu-1.png"/>
      </div>
    </div>
    <div class="pinggufuwu-content1">
      <div class="pinggufuwu-content1-container">
        <div class="section-header">
          <div class="section-header-title">
            我们的评估体系
          </div>
          <div class="section-header-subtitle">
            EVALUATION
          </div>
        </div>
        <div class="section-body">
          <img src="@/assets/pinggufuwu-2.png" class="hidden-md-and-down"/>
          <img src="@/assets/pinggufuwu-1-mobile.png" class="hidden-lg-and-up"/>
        </div>
      </div>
    </div>
    <div class="pinggufuwu-content2">
      <div class="section-header">
        <div class="section-header-title">
          我们的评估服务
        </div>
        <div class="section-header-subtitle">
          OUR ASSESSMENT SERVICE
        </div>
      </div>
      <div class="section-body">
        <div class="section-tab-wrapper">
          <div class="section-tab" v-for="(item,index) in list" :key="index" @click="tabIndex = index"
               :class="{active: tabIndex === index}">
            <img :src="require(`./assets/${item.title}.svg`)"/>
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
        <div class="section-content" v-for="(item,index) in list" :key="index" v-show="tabIndex === index">
          <div class="tabText" v-if="item.tabText">{{ item.tabText }}</div>
          <div v-if="index!==1">
            <div class="barList" v-for="(item1,i) in item.content" :key="i">
              <img :src="require(`@/assets/${item1.name}@2x.png`)">
              <div class="content">
                <div class="title">{{ item1.name }}</div>
                <div class="text">{{ item1.text }}</div>
              </div>
            </div>
          </div>
          <div class="waibu" v-else>
            <div class="itemList" v-for="(item1,i) in item.content" :key="i">
              <img :src="require(`@/assets/${item1.name}@2x.png`)">
              <div class="content">
                <div class="title">{{ item1.name }}</div>
                <div class="text">{{ item1.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./styles/mobile.scss";
import "./styles/pc/pc.scss";

export default {
  name: "Pinggufuwu",
  data() {
    return {
      tabIndex: 0,
      list: [
        {
          title: '车辆数据采集',
          tabText: '',
          content: [
            {
              name: '车辆历史数据采集',
              text: '车银通经过5年不断的数据积累，通过大数据分析、数据清洗和车型整合算法模型，对复杂的车型数据进行拆分、合并、补充残缺数据、移除重复数据处理，建立车辆数据信息中心，目前车辆数据中心主要应用于VIN码识别、品牌车型价格数据支持和API接口服务，支持全车型车辆178项出厂信息查询，并支持新能源200+项车辆出厂信息查询，响应数据快，1秒返回查询结果。'
            },
            {
              name: '车辆现状数据采集',
              text: '车辆现状数据包含：1.按业务要求采集员采集上来的车辆基础数据；2.特殊业务或特殊品牌车系采集项可以进行个性化设置；3.由于车身结构不同和车辆发动机前后位置不同等因素系统会自动识别车型，数据采集位置和采集数量各不同；4.按实际业务和车辆权属情况采集不同类型的手续照片；5.由于车身结构不同和品牌车系不同等因素系统自动识别，漆膜检测点和检测数量不同。'
            },
            {
              name: '车辆未来数据',
              text: '车辆数据信息中心自有车辆保值率算法模型，将各个渠道收集到的二手车交易数据汇总，经过严谨的数据清洗、去除重复或异常数值后，再进行不断的建模训练，可以计算不同品牌车系的保值率和车辆未来价格预测。'
            },
          ]
        },
        {
          title: '外部因素数据采集',
          tabText: '除了车辆本身车况及历史数据外，外部因素对车辆价格也有较大的影响作用，车银通拥有数据庞大的舆情信息系统，车辆评估过程中，设置舆情信息指标及对应关键词权重，充分考虑舆情信息的影响，保证车辆评估价格更加合理',
          content: [
            {
              name: '品牌车系舆情信息',
              text: '品牌车系持续关注内容包括各品牌车系历史召回数据、国家及地区新推出政策对某一品牌或车型的影响（如2019年推出的国四转国五）、市场环境变化或者对外贸易政策变化对车价的影响等。'
            },
            {name: '经销商舆情信息', text: '经销商持续关注内容包括经销商历史工商信息变更、法院执行信息、拖欠合格证、资金链紧张、拖欠员工工资等负面信息预警。'},
            {
              name: '地区灾情信息',
              text: '灾情信息实时关注包括自然灾害或者其他灾害对价格造成的影响。对于地区灾情可具体到地区、年份以及月份，如不同年份、不同地区发生特大洪水、台风等自然灾害记录，天津滨海新区爆炸事故等。'
            },
          ]
        },
        {
          title: '车辆鉴定评估介绍',
          tabText: '每一台车都是独一无二的，二手车鉴定评估工作是一个比较复杂的过程，车银通通过自主产权的技术应用高效检测车辆的真实情况，重点排查事故车、水淹车、火烧车等情况进行精准、公正的车辆剩余市场价值评估，评估后会提供专业的车况检测评估报告， 避免二手车后续安全隐患。',
          content: [
            {
              name: '自动识别技术',
              text: '图片、视频自动识别：对于采集上来的车辆图片信息、手续图片信息或拍摄的视频信息，通过AI智能识别技术，可以自动识别并处理车辆异常情况，并能通过照片或视频进行造假识别。'
            },
            {
              name: '车辆残值模型自动评估',
              text: '影响车辆残值的因素包含车辆价格、品牌价值、车辆保有量、地方政策、城市结构、社会事件、舆论导向等，车银通通过大数据分析，建立车辆评估特性数据模型，通过不断的机器学习来训练评估模型的精准度。'
            },
            {
              name: '资深专家评估',
              text: '（1） 小众车型评估。二手车评估一个重要参考依据是车辆过往数据，但是对于保有量小的车辆（特殊渠道车源的进口车、特种车、定制车），无法获取大量的历史交易数据，缺乏评估的历史数据支持，这种情况下需要经验丰富的评估师进行人工评估。（2） 定制化业务需求。为了满足企业、个人及金融机构特定场景及特定业务需求，可提供定制化人工评估服务。'
            },
          ]
        }
      ]
    };
  },
};
</script>
